import { type SiteConfig } from '#types/index.js'

export const siteConfig: SiteConfig = {
	name: 'Asociación PCDH19',
	description:
		'Somos una asociación de familiares de pacientes con el tipo de epilepsia PCDH19, estamos constituidos en el ministerio del interior como asociación nacional sin ánimo de lucro.',
	url: 'https://pcdh19.com',
	ogImage: 'https://pcdh19.com/img/brand/logo.png',
	links: {
		twitter: 'https://twitter.com/pcdh19',
	},
}
